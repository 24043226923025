<template>
  <form
    ref="formContact"
    data-netlify-recaptcha="true"
    netlify
    method="POST"
    name="contact-form"
    class="form"
    @submit.prevent="submit(values)"
  >
    <div class="base-input-container">
      <base-input
        class="form__field field-animate"
        type="text"
        :label="$t('credentials.name')"
        :placeholder="$t('forms.placeholders.name')"
        :error="errors.name"
        v-model:model-value.lazy.trim="name"
      />
      <base-input
        class="form__field field-animate"
        :placeholder="$t('forms.placeholders.email')"
        type="email"
        :label="$t('credentials.email')"
        :model-value="email"
        :error="errors.email"
        v-model:model-value.lazy.trim="email"
      />
    </div>

    <div class="base-input-container">
      <base-input
        class="form__field field-animate"
        type="text"
        data-mask="+## ### ### ### ### ### #*"
        :label="$t('credentials.phone')"
        :placeholder="$t('forms.placeholders.phone')"
        :error="errors.phoneNumber"
        v-model:model-value.lazy.trim="phoneNumber"
      />

      <base-input
        class="form__field field-animate"
        :placeholder="$t('forms.placeholders.subject')"
        type="text"
        :label="$t('messages.subject')"
        :error="errors.subject"
        v-model:model-value.lazy.trim="subject"
      />
    </div>

    <base-textarea
      class="textarea-field field-animate"
      :placeholder="$t('forms.placeholders.message')"
      :label="$t('messages.message')"
      v-model:model-value.lazy.trim="message"
      @change="handleChange($event, 'message')"
      :error="errors.message"
    />
    <base-button
      type="submit"
      :disabled="!isFormValid || isFetching"
      class="submit-btn field-animate"
      translation-path="buttons.submit"
    />
    <form-character />

    <div ref="contactContainer" class="form__success-container">
      <h3 class="heading heading-tertiary animate">
        {{ $t('generics.success') }}
      </h3>
      <div class="p-wrapper animate">
        <p class="paragraph">{{ $t('contact.form.success-submit') }}</p>
        <p class="paragraph">{{ $t('contact.form.thanks') }}</p>
        <p class="paragraph">{{ $t('contact.form.reply') }}</p>
      </div>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { computed, nextTick, onMounted } from 'vue';
import { gsap } from 'gsap';
import { useField, useForm } from 'vee-validate';
import { useBreakpoints } from '@vueuse/core';

import FormCharacter from '@/components/library/illustrations/FormCharacter';
import BaseInput from '@/components/library/form/BaseInput';
import BaseTextarea from '@/components/library/form/BaseTextarea';
import { useAppStore } from '@/stores/appStore';
import useMessageStore from '@/stores/messageStore';

const formContact = ref<HTMLFormElement | null>(null);
const contactContainer = ref<HTMLDivElement>();
// ************* STORES ************* //
const { state: appState } = useAppStore();
const { submitForm } = useMessageStore();
const { call, isFetching } = useApi();
// ************* COMPOSABLES ************* //
const { checkPhoneNumber, checkValidEmail, required, minLength } =
  useValidations();
const mail = useMail();
// ************* GETTERS ************* //
const isFormValid = computed(() => {
  return !Object.keys(errors.value).length;
});

/// ////////////////////
// Animating Character
/// ////////////////////
async function animateCharacter() {
  if (!document) {
    return;
  }
  const characterAnimation = function (entries) {
    if (!appState.nodes.contactPage) return;

    const [entry] = entries;
    if (!appState.nodes.character.eyeRight) return;
    if (entry.isIntersecting) {
      // TimeLines
      const tlFace = gsap.timeline({ default: { duration: 3 } });
      // Line path for the smiling mouth
      const start = 'M195.5 118C190.333 118.255 187.471 118.434 182 118';
      const end = 'M195.5 118C190.356 120.454 187.563 120.771 182 118';
      // Animation for the right eye
      gsap.set(appState.nodes.character.eyeRight, {
        transformOrigin: 'center',
      });
      tlFace.fromTo(
        appState.nodes.character.eyeRight,
        { scaleY: 1, repeat: -1, repeatDelay: 2 },
        { scaleY: 0.6, repeat: -1, yoyo: true, repeatDelay: 2 },
      );
      // Animation for the mouth
      tlFace.fromTo(
        appState.nodes.character.mouth,
        {
          attr: { d: start },
          repeatDelay: 2,
        },
        { attr: { d: end }, yoyo: true, repeat: -1, repeatDelay: 2 },
        '<',
      );
    }
  };
  const contactObserver = new IntersectionObserver(characterAnimation, {
    root: null,
    threshold: 0,
  });
  contactObserver.observe(appState.nodes.contactPage);
}

/// ///////////////////
// Animation for form on click
/// /////////////////////
function formSubmissionAnimation() {
  const submitTl = gsap.timeline({
    defaults: { duration: 0.75, ease: 'Power2.easeOut' },
  });
  const endPath = 'M195.5 118C190.356 120.454 187.563 120.771 182 118';

  submitTl.to('.field-animate', {
    y: 30,
    opacity: 0,
    pointerEvents: 'none',
  });

  submitTl.to(
    formContact.value,
    {
      scale: 0.8,
    },
    '<',
  );

  gsap.set(appState.nodes.character.armLeft, { transformOrigin: 'right' });
  submitTl.fromTo(
    appState.nodes.character.armLeft,
    { rotate: 0 },
    { rotate: -10, ease: 'elastic.out(3, 0.5)', duration: 2, delay: 1 },
  );

  submitTl.to(appState.nodes.character.mouth, { attr: { d: endPath } });

  submitTl.fromTo(
    contactContainer.value,
    { opacity: 0, height: 0, width: 0 },
    { opacity: 1, height: 'min-content', width: '80%' },
    '<',
  );
  submitTl.fromTo(
    '.animate',
    { opacity: 0, scale: 0.8 },
    { opacity: 1, scale: 1 },
  );
  gsap.set(appState.nodes.character.cloud, { zIndex: 4 });
  submitTl.fromTo(
    appState.nodes.character.cloud,
    { y: 0, x: 0, opacity: 1 },
    { y: '-1000%', x: '-200%', opacity: 0, duration: 20 },
    '<',
  );
}

const validationSchema = {
  name: (value) => {
    const req = required(value);
    if (req !== true) {
      return req;
    }
    const min = minLength(3, value);
    if (!!min) {
      return min;
    }
    return true;
  },
  email: (value) => {
    const email = checkValidEmail(value);
    if (email !== true) {
      return email;
    }
    return true;
  },
  phoneNumber: (value) => {
    const phone = checkPhoneNumber(value);
    if (phone !== true) {
      return phone;
    }
    return true;
  },
  subject: (value) => {
    const req = required(value);
    if (req !== true) {
      return req;
    }
    return true;
  },
  message: (value) => {
    const req = required(value);
    if (req !== true) {
      return req;
    }
    return true;
  },
};

const { handleSubmit, errors, values } = useForm({
  validationSchema,
  initialValues: {
    name: '',
    email: '',
    phoneNumber: '',
    subject: '',
    message: '',
  },
});

const { value: email } = useField<string>('email');
const { value: name } = useField<string>('name');
const { value: phoneNumber } = useField<string>('phoneNumber');
const { value: subject } = useField<string>('subject');
const { value: message } = useField<string>('message');

const adminEmail = import.meta.env.VITE_ADMIN_EMAIL;
const submit = handleSubmit(async (values) => {
  try {
    const text = `email of ${values.email} \n \n name: ${values.name} \n \n phone number: ${values.phoneNumber} \n \n ${values.message}`;
    const receiver = {
      from: values.email,
      to: adminEmail,
      subject: values.subject,
      phoneNumber: values.phoneNumber,
      text,
    };
    const res = await call(
      async () =>
        await Promise.all([
          mail.send(receiver),
          submitForm({ adminEmail: adminEmail, ...values }),
        ]),
    );
    formSubmissionAnimation();
  } catch (e) {
    console.error(e);
  }
});

const breakpoints = useBreakpoints({ desktop: 1000 });

onMounted(async () => {
  if (breakpoints.isGreaterOrEqual('desktop')) {
    await nextTick(async () => {
      setTimeout(() => {
        animateCharacter();
      }, 1000);
    });
  }
});
</script>

<style lang="scss" scoped>
.form {
  background-color: var(--color-black);
  box-shadow: -1px 14px 35px 5px rgba(0, 0, 0, 0.47);
  -webkit-box-shadow: -1px 14px 35px 5px rgba(0, 0, 0, 0.47);
  -moz-box-shadow: -1px 14px 35px 5px rgba(0, 0, 0, 0.47);
  border: $border-card solid transparent;
  border-radius: $border-radius-default;
  background-clip: padding-box;
  display: flex;
  gap: $gap-lg;
  flex-wrap: wrap;
  padding: 6rem 4rem 12rem 4rem;
  position: relative;
  max-width: 60rem;
  align-items: flex-start;

  @include respond(phone) {
    padding: 4rem 2rem 25rem 2rem;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -$border; /* !important */
    border-radius: inherit; /* !important */
    border: 3px solid $color-primary;
  }

  .base-input-container {
    display: flex;
    gap: $gap-small;
    width: 100%;

    @include respond(phone) {
      flex-direction: column;
      gap: $gap-lg;

      > * {
        flex-basis: 100%;
      }
    }

    > * {
      flex-grow: 1;
    }
  }

  .textarea-field {
    width: 100%;
    min-height: 20rem;
  }

  .submit-btn {
    margin-top: 4rem;
  }

  &__icon {
    position: absolute;
    bottom: -7%;
    right: 5%;
    overflow: visible;
    height: 25rem;

    @include respond(phone) {
      height: 25rem;
      bottom: -4.3%;
      right: 6%;
    }
  }

  &__success-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 0;
    height: 0;
    top: 45%;
    left: 50%;
    z-index: 0;
    opacity: 0;

    -webkit-clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 75%,
      75% 75%,
      75% 100%,
      50% 75%,
      0% 75%
    );
    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 75%,
      75% 75%,
      75% 100%,
      50% 75%,
      0% 75%
    );
    transform: translate(-50%, -50%);
    background-color: var(--color-input-half);
    padding: 3rem 3rem 10rem 3rem;

    & .heading {
      font-size: 4rem;
      text-transform: uppercase;
      color: var(--color-success);
      margin-bottom: 1rem;
    }

    & .paragraph {
      margin-bottom: 2rem;
      font-size: 1.6rem;
      line-height: 1.3;
    }
  }

  &__field {
    @include respond(phone) {
      flex-basis: 100%;
    }
  }
}

.form__success-container {
  border-radius: 2rem;
}
</style>
