<template>
  <keep-alive>
    <section ref="aboutPageNode" id="about" class="section-about">
      <div class="content-wrapper">
        <div class="section-about__img-container">
          <img
            src="@/assets/svgs/bk_blur_about.svg"
            alt="blurry background of a circle"
            :ref="(el) => addNode('aboutBkBlur', <HTMLImageElement>el)"
            class="img-container__bk-blur"
          />
          <base-img
            v-if="portraitSrc"
            :renditions="portraitSrc"
            :ref="
              (
                component: Element | ComponentPublicInstance | null,
                refs: Record<string, any>,
              ) => setNode(component, 'aboutPortrait')
            "
            class="img-container__portrait"
            alt-text="Portrait of Benjamin Scharf"
            :use-stripes="true"
            :set-absolute="true"
          />
        </div>

        <div class="section-about__txt-container">
          <div class="txt-container__heading-container">
            <h2
              :ref="(el) => setNode(el, 'aboutHeading')"
              class="heading-about"
            >
              {{ $t('about.title') }}
            </h2>
          </div>

          <base-list class="list" :list-infos="stackInfos" />
          <div
            :ref="(el) => setNode(el, 'aboutBtnContainer')"
            class="btn-container"
          >
            <base-button
              :link="CV"
              icon="cloud-arrow-down"
              color="fill-primary"
              download-name="Benjamin_Scharf_CV"
              translation-path="about.cv"
            />
          </div>
        </div>
      </div>

      <!-- MODAL FOR ABOUT -->
    </section>
  </keep-alive>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, onUnmounted } from 'vue';
import type { ComponentPublicInstance } from 'vue';
import { useI18n } from 'vue-i18n';
import CV from '@/assets/files/CV_Benjamin_Scharf.pdf';
import BaseList from '@/components/library/list/BaseList.vue';
import portraitOpt from '@/assets/img/optimised/Portrait_Ben.webp';
import portraitAlt from '@/assets/img/raw/Portrait_Ben.png';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useBreakpoints } from '@vueuse/core';
import { useAppStore } from '@/stores/appStore';

const breakpoints = useBreakpoints({ desktop: 1000 });
const { state: appState } = useAppStore();
const { addNode } = useAppStore();
const { t } = useI18n({ useScope: 'global' });

const portraitSrc = {
  opt: portraitOpt,
  alt: portraitAlt,
};

const stackInfos = computed(() => [
  { main: t('about.quality-1.main'), side: t('about.quality-1.side') },
  { main: t('about.quality-2.main'), side: t('about.quality-2.side') },
  { main: t('about.quality-3.main'), side: t('about.quality-3.side') },
  { main: t('about.quality-4.main'), side: t('about.quality-4.side') },
  { main: t('about.quality-5.main'), side: t('about.quality-5.side') },
]);

const aboutPageNode = ref<HTMLElement>();

// let ctx;
let timeline;

function initializeTimeline() {
  if (
    !aboutPageNode.value ||
    !appState.nodes.aboutHeading ||
    !appState.nodes.aboutPortrait ||
    !appState.nodes.aboutBkBlur ||
    !appState.nodes.qualityItem ||
    !appState.nodes.aboutBtnContainer
  ) {
    return;
  }
  timeline = gsap.timeline({
    scrollTrigger: {
      trigger: '#about',
      anticipatePin: 1,
      // markers: false,
      // markers: { startColor: 'pink', endColor: 'pink' },
      start: '-20%',
      end: '80%',
      pin: true,
      scrub: true,
    },
  });

  timeline.fromTo(appState.nodes.aboutHeading, { opacity: 0 }, { opacity: 1 });

  timeline.fromTo(
    appState.nodes.aboutPortrait,
    { rotate: 0, opacity: 0 },
    { rotate: 45, duration: 4000, opacity: 1 },
  );

  timeline.fromTo(
    appState.nodes.aboutBkBlur,
    { opacity: 0 },
    { opacity: 1, duration: 3000 },
    '<',
  );

  appState.nodes.qualityItem.forEach((item: HTMLElement) => {
    if (item?.innerHTML) {
      timeline.fromTo(
        item,
        { opacity: 0, y: -10 },
        { opacity: 1, y: 0, stagger: 0.5, duration: 3000 },
      );
    }
  });

  timeline.fromTo(
    appState.nodes.aboutBtnContainer,
    { opacity: 0 },
    { opacity: 1, duration: 3000 },
  );
}

function cleanupTimeline() {
  // Clean up your GSAP timeline here
  if (timeline) {
    timeline.kill();
    timeline = null;
  }
}

function setNode(el: HTMLElement, name: string) {
  if (!el) return;
  if (name === 'aboutPortrait') {
    addNode(name, el.$el);
  } else {
    addNode(name, el);
  }
}

onMounted(() => {
  if (breakpoints.greaterOrEqual('desktop').value) {
    gsap.registerPlugin(ScrollTrigger);
    cleanupTimeline();
    initializeTimeline();
  }
});

onUnmounted(() => {
  // ctx.revert();
  cleanupTimeline();
});
</script>

<style scoped lang="scss">
.section-about {
  display: flex;
  position: relative;
  height: 80rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 8rem;
  left: 0 !important;
  right: 0 !important;

  .heading-about {
    margin-bottom: 2rem;
  }

  .list {
    position: relative;
    left: 2rem;
  }

  @include respond(tab-land) {
    height: auto;
    padding: 0rem 0rem 0rem 0rem;
    margin-bottom: 2rem;
  }

  @include respond(tab-port-small) {
    flex-direction: column;
  }

  .content-wrapper {
    display: flex;
    align-items: center;
    gap: 18rem;
    max-width: 140rem;

    @include respond(tab-port-small) {
      flex-direction: column;
      gap: 0;
    }

    @include respond(phone) {
      padding: 2rem 2rem;
    }
  }

  & .txt-container__heading-container {
    display: flex;
  }

  &__img-container {
    position: relative;

    @include respond(tab-land) {
      margin-bottom: 12rem;
    }
  }

  .img-container__bk-blur {
    position: absolute;

    @include respond(tab-port-small) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__txt-container {
    margin-right: 9rem;
    padding-bottom: 2rem;

    @include respond(tab-land) {
      margin-right: 0;
    }

    &::before {
      content: '';
      background-image: url('@/assets/img/optimised/Coding_Table_Edited.webp');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 100%;
      position: absolute;
      display: block;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -30;
      opacity: 6%;

      @include respond(tab-land) {
        background-position: top center;
      }
    }
  }

  .btn-container {
    display: flex;
    align-items: center;
    gap: 2rem;

    :deep(.icon) {
      width: 2rem;
      height: 2rem;
      margin-right: 1rem;
    }
  }
}
</style>
