<template>
  <h1>
    <span class="text-white-100"> {{ $t('hero.name') }} </span>
    <span class="heading-h1__sub">
      {{ $t('hero.profession') }}
    </span>
  </h1>
  <p class="paragraph hero__paragraph">
    {{ $t('hero.summary.first') }} <br />
    {{ $t('hero.summary.second') }}<br />
    {{ $t('hero.summary.third') }}
  </p>
  <base-button link="#projects" class="btn-projects">
    {{ $t('buttons.projects') }}
  </base-button>
</template>
<script setup lang="ts"></script>

<style scoped lang="scss">
h1 {
  margin-bottom: 2rem;
}

.paragraph {
  @include respond(phone) {
    font-size: 2rem;
  }
}

.btn-projects {
  display: inline-block;
}

.heading {
  &__person-name {
    display: block;
  }

  &-h1__sub {
    font-size: 6.3rem;
    color: var(--color-grey-txt);
    display: block;
  }
}

.paragraph {
  line-height: 1.8;
  font-size: 2rem;
  margin-bottom: 4rem;

  &__link {
    text-decoration: underline;
    text-transform: uppercase;
    transition: 250ms all ease-out;

    &:hover {
      color: var(--color-primary);
    }
  }
}
</style>
