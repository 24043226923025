<template>
  <section ref="projectSection" id="projects" class="section-projects">
    <transition name="fade-up" appear>
      <div v-if="isInViewport">
        <div class="bk-universe">
          <svg class="bk-universe__content">
            <use xlink:href="@/assets/sprite.svg#Universe" />
          </svg>
        </div>
        <h2 class="heading heading-2">
          {{ $t('projects.title') }} <span> {{ $t('projects.title-2') }} </span>
        </h2>
        <p class="paragraph">
          {{ $t('projects.sub-heading') }}
        </p>
        <Splide
          class="splide-wrapper"
          :options="settings"
          aria-label="Projects"
        >
          <splide-slide
            v-for="(project, index) in projects.list"
            :key="project.id + index"
          >
            <base-slide
              class="slide"
              v-bind="project"
              @handle:events="handleEvents($event)"
            />
          </splide-slide>
        </Splide>
      </div>
    </transition>
  </section>
</template>

<script lang="ts" setup>
import BaseSlide from '@/components/library/carousel/BaseSlide';
import { useProjectsStore } from '@/stores/projectsStore';
import type { ProjectEvent } from '@/components/library/carousel/BaseSlide';
import { useBreakpoints, useIntersectionObserver } from '@vueuse/core';

const { state: projects } = useProjectsStore();
const router = useRouter();
const breakpoints = useBreakpoints({ tablet: 1000 });
const projectSection = ref<HTMLElement>();
const isInViewport = ref(false);

const section = useIntersectionObserver(
  projectSection,
  ([{ isIntersecting }], observerElement) => {
    isInViewport.value = isIntersecting;
    if (isIntersecting) {
      observerElement.unobserve(projectSection.value as HTMLElement);
    }
  },
  {
    rootMargin: '-100px',
  },
);
const settings = {
  type: 'loop',
  rewindByDrag: true,
  perPage: 4,
  transition: 300,
  breakpoints: {
    800: { perPage: 1 },
    1500: { perPage: 2 },
    1700: { perPage: 3 },
    // 1750: { perPage: 4 },
  }, // object
  paginationEnabled: true,
  autoplay: 0,
  pauseOnHover: true,
  gap: '0rem',
  // padding: '8rem',
};

const handleEvents = ({ eventName, info: { title, id } }: ProjectEvent) => {
  if (eventName === 'open-modal') {
    router.push({
      name: breakpoints.isGreaterOrEqual('tablet')
        ? 'index-project-name-id'
        : 'project-name-id',
      params: { name: title, id: id },
    });
  }
};
</script>

<style scoped lang="scss">
.section-projects {
  padding: 4rem 10rem;
  justify-content: space-evenly;
  position: relative;
  overflow: hidden;
  text-align: center;
  margin: 0 auto $spaceing-page;

  .splide-wrapper {
    :deep(path) {
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }

  .slide {
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  :deep(.splide) {
    padding: 5rem;

    @include respond(phone) {
      padding: 1rem;
    }

    .splide__track {
      overflow: hidden;
    }
  }

  :deep(.splide__slide) {
    display: flex !important;
    justify-content: center !important;
  }

  @include respond(tab-land) {
    padding: 0rem 4rem 8rem 4rem;
  }

  @include respond(phone) {
    margin-bottom: 4rem;
    padding: 0;
  }

  .bk-universe {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -10;

    &__content {
      width: 100%;
      height: 100%;
    }
  }

  .heading-2 {
    text-align: center;
    flex-basis: 100%;
    margin-bottom: 2rem;
  }

  .paragraph {
    font-size: $default-font-size;
  }
}
</style>
