<template>
  <div class="bg-video">
    <video
      src="@/assets/videos/smoke-video.mp4"
      class="bg-video__content"
      autoplay
      muted
      loop
      playsinline
    >
      <source src="@/assets/videos/smoke-video.mp4" type="video/mp4">
      <source src="@/assets/videos/smoke-video.webm" type="video/webm">
      Your browser does not support video!
    </video>
  </div>
</template>
<script setup lang="ts"></script>
<style lang="scss">
.bg-video {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 8%;

  &__content {
    height: 100%;
    width: 100%;
    display: block;
    object-fit: cover;
    object-position: center;
  }
}
</style>
