<template>
  <section ref="chartSection">
    <h3 class="heading">Skill Stats</h3>
    <transition name="fade">
      <div v-if="isChartInView">
        <base-chart>
          <h4 class="heading">Skill Stats</h4>
        </base-chart>
      </div>
    </transition>
  </section>
</template>

<script lang="ts" setup>
import { useIntersectionObserver } from '@vueuse/core';
const isChartInView = ref(false);
const chartSection = ref<HTMLElement>();

useIntersectionObserver(
  chartSection,
  ([{ isIntersecting }], observerEl) => {
    isChartInView.value = isIntersecting;
    if (isIntersecting) {
      observerEl.unobserve(chartSection.value as HTMLElement);
    }
  },
  {
    rootMargin: '-100px',
  },
);
</script>

<style lang="scss" scoped>
section {
  max-width: 150rem;
  margin: 0 auto $spaceing-page;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 6rem;
  border-radius: $border-default;
  transition: transform 250ms ease-out;

  @include respond(phone) {
    padding: 2rem;
    margin-bottom: $spacing-small--2;
  }

  //&:hover {
  //  transform: scale(1.05);
  //  box-shadow: $shadow-medium;
  //
  //  @include respond(tab-port) {
  //    transform: scale(1);
  //  }
  //}

  h3,
  h4 {
    margin-bottom: $spacing-small--1;
  }

  h4 {
    text-align: right;
  }
}
</style>
