<template>
  <section
    id="contact"
    :ref="
      (el: HTMLElement) => {
        contactPage = el;
        addNode('contactPage', el);
      }
    "
    class="section-contact u-margin-bottom-page"
    @mouseover="getCoordsAndMoveBubble"
  >
    <div class="container-contact-content">
      <div ref="$textContainer" class="section-contact__txt-container">
        <h2 class="heading heading-2">
          {{ $t('contact.cta') }} <br />
          {{ $t('contact.cta-1') }}
        </h2>
        <p class="paragraph">
          {{ $t('contact.paragraph') }} <br />
          {{ $t('contact.paragraph-1') }}
        </p>
        <p class="paragraph">
          {{ $t('contact.paragraph-2') }}
          <br />
          {{ $t('contact.paragraph-3') }} ⬇️
        </p>

        <a
          href="mailto:bentec@workmail.com"
          title="Open Email for bentec@workmail.com"
          class="btn-link btn-link__email"
        >
          bentec@workmail.com</a
        >

        <base-bubbles
          :src-path="ringPng"
          alt-text="Ring floating around the page"
          ref="ring"
          class="ring ring-container"
        />
      </div>
      <div ref="$contactForm" class="contact-form">
        <contact-form
          class="section-contact__form-container u-margin-bottom-medium"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { gsap } from 'gsap';
import { ref } from 'vue';
import type { ComponentPublicInstance } from 'vue';

import { useIntersectionObserver } from '@vueuse/core';
import ringPng from '@/assets/img/donut.png';
import ContactForm from '@/components/sections/contact/ContactForm';
import { useAppStore } from '@/stores/appStore';

const $textContainer = ref<HTMLDivElement>();
const $contactForm = ref<HTMLDivElement>();
const ring = ref<ComponentPublicInstance>();
const { state: appState, addNode } = useAppStore();

const contactPage = ref<HTMLElement>();

// let mediaQuery

function getCoordsAndMoveBubble(event) {
  const x = event.clientX;
  const y = event.clientY;

  moveRing(x, y);
}

function moveRing(x, y) {
  if (!ring.value) return;
  gsap.to(ring.value.$el, { x: `${x / 50}%`, y: `${y / 50}%` });
}

function parallaxEffectContact() {
  if (
    !appState.nodes.contactPage ||
    !$contactForm.value ||
    !$textContainer.value
  ) {
    return;
  }
  const parallaxTl = gsap.timeline({
    scrollTrigger: {
      trigger: '#contact',
      start: '0',
      end: '-20%',
    },
  });

  parallaxTl.fromTo(
    $textContainer.value,
    { y: 0, x: -200, rotateY: 90 },
    { y: 0, x: 0, rotateY: 0 },
  );
  parallaxTl.fromTo(
    $contactForm.value,
    { y: 0, x: 200, rotateY: 90 },
    { y: 0, x: 0, rotateY: 0 },
    '<',
  );
}

useIntersectionObserver(
  contactPage,
  ([{ isIntersecting }], observerEl) => {
    if (!isIntersecting) return;
    parallaxEffectContact();
    observerEl.unobserve(contactPage.value as HTMLElement);
  },
  {
    rootMargin: '-300px',
  },
);
</script>

<style lang="scss" scoped>
.section-contact {
  padding: 6rem 2rem;
  position: relative;

  @include respond(phone) {
    padding: 2rem 1rem;
  }

  .container-contact-content {
    display: flex;
    max-width: 120rem;
    margin: 0 auto 12rem;
    justify-content: space-between;
    @include respond(tab-land) {
      flex-direction: column;
    }
  }

  &__txt-container {
    position: relative;
    margin-top: 4rem;

    @include respond(tab-land) {
      margin-top: 0;
    }

    .paragraph {
      font-size: $font-medium;
    }

    .heading,
    .paragraph,
    .btn-link {
      display: block;
      text-align: left;
      margin-bottom: 3rem;

      @include respond(tab-land) {
        text-align: center;
        margin-bottom: 3rem;
      }
    }

    .btn-link {
      display: inline-block;

      &:link,
      &:visited {
        color: var(--color-white);
        text-decoration: none;

        position: relative;

        &:before {
          content: '';
          position: absolute;
          width: 0;
          height: 4px;
          bottom: 0;
          left: 0;
          background: $gradient-primary-left-to-right;
          border-radius: $border-radius-default;
          transition: all 0.3s ease-in-out;
        }

        &:hover:before {
          visibility: visible;
          width: 100%;
        }
      }

      @include respond(tab-land) {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    & .ring-container {
      position: absolute;
      height: 17rem;

      left: 1%;
      bottom: 0%;

      @include respond(tab-land) {
        display: none;
      }
    }
  }

  &__form-container {
    @include respond(tab-land) {
      margin: 0 auto;
      //margin-top: 5rem;
    }
  }
}

.bg-video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &__content {
    width: 100%;
    object-fit: cover;
    opacity: 8%;
    z-index: -20;

    @include respond(tab-land) {
      height: 100vh;
    }
  }
}
</style>
