<template>
  <Splide :options="settings" class="splide-container" aria-label="Projects">
    <SplideSlide
      v-for="blog in featuredPosts"
      :key="blog.id"
      class="container-splide"
    >
      <base-blog-card class="blog-cards" :blog="blog" :path="blog._path" />
    </SplideSlide>
  </Splide>
</template>

<script lang="ts" setup>
import type { ParsedContent } from '@nuxt/content/dist/runtime/types';
import { watchEffect } from 'vue';

const featuredPosts = ref([]);

const { data } = await useAsyncData('blogContent', () =>
  queryContent<ParsedContent>('/blog').find(),
);

watchEffect(() => {
  if (data.value) {
    featuredPosts.value = data.value.filter((d) => d.featured_At_UTC);
  }
});

const settings = {
  type: 'loop',
  rewindByDrag: true,
  perPage: 4,
  transition: 300,
  breakpoints: {
    760: { perPage: 1 },
    1461: { perPage: 2 },
    1750: { perPage: 4 },
  }, // object
  paginationEnabled: true,
  autoplay: 0,
  pauseOnHover: true,
  navigationNextLabel: 'next',
  navigationPrevLabel: 'previous',
  paginationActiveColor: '#000',
  paginationColor: '#efefef',
  gap: '1em',
  lazyLoad: true,
  padding: '2rem',
};
</script>

<style lang="scss" scoped>
.splide-container {
  padding: 4rem;
  @include respond(phone) {
    padding: 4rem 0;
  }
}

.blog-cards {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

//.card-wrapper {
//  padding: 2rem;
//  border: 2px solid white;
//  border-radius: $border-radius-card;
//}
//
//.thumbnail {
//  margin-bottom: 1rem;
//  overflow: hidden;
//  border-radius: $border-radius-card;
//  aspect-ratio: 16 / 9;
//}
//
//.blog-heading {
//  margin-bottom: 2rem;
//  display: -webkit-box;
//  -webkit-line-clamp: 2;
//  -webkit-box-orient: vertical;
//  overflow: hidden;
//  text-overflow: ellipsis;
//}
//
//.wrapper {
//  display: flex;
//  justify-content: space-between;
//  align-items: center;
//  margin-bottom: 2rem;
//
//  .date {
//    font-size: 1.6rem;
//    color: var(--color-grey-txt);
//  }
//}
//
//.wrapper-badges {
//  display: flex;
//  gap: 1rem;
//  overflow-x: auto;
//}
</style>
