<script setup lang="ts">
import { ref } from 'vue';
import LazyChat from '@/components/chats/LazyChat.vue';
import { useBreakpoints } from '@vueuse/core';
import { useAppStore } from '@/stores/appStore';

const isOpen = ref(false);
const router = useRouter();
const { toggleMobileNav } = useAppStore();

const breakpoints = useBreakpoints({ tablet: 800 });

function handleOpenChat() {
  if (breakpoints.isGreater('tablet')) {
    isOpen.value = !isOpen.value;
  } else {
    toggleMobileNav(false);
    router.push({ name: 'chat' });
  }
}
</script>

<template>
  <teleport to="body">
    <div class="controller-container">
      <transition name="fade-scale-up-rounded">
        <lazy-chat class="chat-container" v-show="isOpen" />
      </transition>
      <base-button
        class="btn"
        icon="cursor-arrow-ripple"
        :disable-hover-effect="true"
        color="tertiary"
        @click="handleOpenChat"
      >
        <base-icon
          :icon="
            isOpen || $route.name === 'chat'
              ? 'chat-bubble-left-oval-left-ellipsis-solid'
              : 'chat-bubble-left-oval-left-ellipsis-reg'
          "
        />
      </base-button>
    </div>
  </teleport>
</template>

<style scoped lang="scss">
.controller-container {
  position: fixed;
  z-index: 30;
  bottom: 4rem;
  right: 4rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  .btn {
    :deep(.btn) {
      border-radius: 100%;
      width: 6rem;
      height: 6rem;
      padding: 0;
      align-items: center;
      justify-content: center;

      &:hover {
        background: var(--color-primary-shade-300);
        box-shadow: 0 0 20px 10px var(--color-primary-shade-500);
        transform: scale(1.1);
      }
    }
  }

  :deep(.icon) {
    display: block;
    //transform: translate(-50%, -20%);
  }

  .chat-container {
    box-shadow: -5px -5px 10px 1px var(--color-primary-shade-500);
    position: absolute;
    color: var(--color-white);
    transform: translate(-100%, -100%);
    overflow: hidden;
  }
}
</style>
