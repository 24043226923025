<script setup lang="ts">
import { watch, ref } from 'vue';
import { gsap } from 'gsap';
import BaseBubbles from '@/components/BaseBubbles.vue';

interface Props {
  coordinates: {
    x: number;
    y: number;
  };
}

const props = defineProps<Props>();

const bubble = ref<InstanceType<typeof BaseBubbles>>(null);
const ring = ref<InstanceType<typeof BaseBubbles>>(null);

watch(
  () => props.coordinates,
  ({ x, y }) => {
    moveBubble(x, y);
    moveRing(x, y);
  },
);

if (process.client) {
  const mediaQuery = window?.matchMedia('(min-width: 1000px');
}

function moveBubble(x, y) {
  if (!bubble.value) return;

  gsap.to(bubble.value.$el, { x: `${x / 100}%`, y: `${y / 100}%` });
}

function moveRing(x, y) {
  if (!bubble.value) return;
  gsap.to(ring.value.$el, { x: `${x / 100}%`, y: `${y / 100}%` });
}
</script>

<template>
  <transition name="fade" appear>
    <base-bubbles
      ref="bubble"
      png="bubble"
      alt-text="Bubble floating around the page"
      aria-label="Bubble floating around the page"
      class="bubble bubble-container"
    />
  </transition>
  <transition name="fade" appear>
    <base-bubbles
      ref="ring"
      png="ring"
      alt-text="Ring floating around the page"
      aria-label="Ring floating around the page"
      class="ring ring-container"
    />
  </transition>
</template>

<style scoped lang="scss">
.bubble-container {
  position: absolute;
  // height: 28rem;
  right: 5%;
  top: 10%;

  @include respond(tab-land) {
    height: 23rem;
    width: 23rem;
    top: 45%;
    right: 10%;
  }
  @include respond(tab-small) {
    top: 60%;
  }

  @include respond(phone) {
    display: none;
  }
}

.ring-container {
  right: 30%;
  top: 50%;
  height: 22rem;

  @include respond(tab-land) {
    display: none;
  }
}
</style>
