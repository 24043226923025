import { useI18n } from 'vue-i18n';

const useValidations = () => {
  //  ************ //
  // Validation function
  //  ************ //
  const { t } = useI18n();

  function checkValidEmail(value: string) {
    // Check for empty input
    if (!value) {
      return t('forms.errors.required');
    }
    // Check for validate email
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(String(value).toLowerCase())) {
      return t('forms.errors.email');
    }

    return true;
  }

  function checkPhoneNumber(value: string) {
    const regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
    if (!regex.test(String(value))) {
      return t('forms.errors.phone');
    }
    return true;
  }

  function required(value: string) {
    const requiredMessage = t('forms.errors.required');
    if (!value) {
      return requiredMessage;
    }
    if (!String(value).length) {
      return requiredMessage;
    }
    return true;
  }

  function minLength(length: number, value: string) {
    if (String(value).length < length) {
      return t('forms.errors.phone', { length: length });
    }
    return true;
  }

  return {
    checkValidEmail,
    checkPhoneNumber,
    required,
    minLength,
  };
};

export default useValidations;
