<template>
  <section ref="blogSection" class="section-featured-blogs">
    <h3 class="heading">Featured <span>Blog </span></h3>
    <div class="btn-wrapper">
      <h4 class="sub-heading">Articles & Resources</h4>
      <nuxt-link to="/blog">
        <base-button class="btn-blogs" color="neutral">
          See All Blogs
        </base-button>
      </nuxt-link>
    </div>
    <transition name="fade-left" appear>
      <featured-blogs v-if="isBlogInView" />
    </transition>
  </section>
</template>

<script lang="ts" setup>
import FeaturedBlogs from '@/components/sections/blogs/FeaturedBlogs.vue';
import { useIntersectionObserver } from '@vueuse/core';

const isBlogInView = ref(false);
const blogSection = ref<HTMLElement>();
useIntersectionObserver(
  blogSection,
  ([{ isIntersecting }], observerEl) => {
    isBlogInView.value = isIntersecting;
    if (isIntersecting) {
      observerEl.unobserve(blogSection.value as HTMLElement);
    }
  },
  {
    rootMargin: '-100px',
  },
);
</script>

<style lang="scss" scoped>
.section-featured-blogs {
  @include respond(medium-desktop) {
    margin-bottom: $spacing-small--2;
  }
}

.btn-blogs {
  @include respond(phone) {
    margin-bottom: 4rem;
  }
}

section {
  max-width: 140rem;
  margin: 0 auto $spaceing-page;
  @include respond(phone) {
    padding: 0;
    margin-bottom: $spacing-small--2;
  }

  h3 {
    margin-bottom: $spacing-small;
    text-align: right;
    @include respond(phone) {
      padding: 0 2rem;
    }

    span {
      display: block;
    }
  }

  .sub-heading {
    color: var(--color-black-shade);
  }

  .btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding: 0 4rem;

    @include respond(phone) {
      display: block;

      > :first-child {
        margin-bottom: 2rem;
      }
    }
  }
}
</style>
