<template>
  <div class="charts-container">
    <nuxt-error-boundary>
      <bar-chart
        :data="barData"
        :options="chartOptions"
        class="charts-container__bar"
      />
      <slot />
      <div class="wrapper">
        <pie-chart
          :data="pieData"
          :options="chartOptions"
          class="wrapper__pie"
        />
        <polar-chart
          :data="polarData"
          :options="chartOptions"
          class="wrapper__polar"
        />
      </div>
      <template #error="slotProps">
        <base-button @click="reloadCharts(slotProps)"
          >Reload charts</base-button
        >
      </template>
    </nuxt-error-boundary>
  </div>
</template>

<script lang="ts" setup>
import { useChartStore } from '@/stores/chartStore';
import BarChart from '@/components/charts/BarChart.vue';
import PieChart from '@/components/charts/PieChart.vue';
import PolarChart from '@/components/charts/PolarChart.vue';

const { barData, polarData, pieData, options: chartOptions } = useChartStore();

const reloadCharts = ({ clearError }) => {
  clearError();
};
</script>

<style lang="scss" scoped>
.charts-container {
  &__bar {
    margin-bottom: 8rem;
  }
}

.wrapper {
  display: flex;
  justify-content: center;
  gap: 20rem;
  margin-top: 2rem;
  @include respond(tab-land) {
    align-items: center;
    flex-direction: column;
    gap: 10rem;
  }

  &__pie {
    @include respond(phone) {
      max-width: none;
      flex-basis: 100%;
      width: 90vw;
      height: 50vh;
    }
    max-width: 50rem;
    flex-basis: 50%;
  }

  &__polar {
    @include respond(phone) {
      max-width: none;
      height: 50vh;
      flex-basis: 100%;
      width: 90vw;
    }
    max-width: 50rem;
    flex-basis: 50%;
  }
}
</style>
