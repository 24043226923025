<template>
  <base-card class="card-wrapper">
    <nuxt-link :to="path">
      <base-img
        class="thumbnail"
        alt-text="thumbnail for an image"
        :renditions="{
          opt: blog.thumbnail,
          raw: blog.thumbnail,
        }"
        :use-stripes="false"
      />
      <!--        :alt-text="blog.thumbnail.alt"-->
      <div>
        <h4 class="blog-heading">
          {{ blog.title }}
        </h4>
        <div class="wrapper">
          <h5 v-if="useCategory">
            {{ blog.category }}
          </h5>
          <span v-if="blog.date" class="date">{{
            convertTimestampToDate(blog.date)
          }}</span>
        </div>
        <div class="wrapper-badges">
          <base-badge
            v-for="(tagName, index) in blog.tags"
            v-if="blog.tags"
            :key="index"
          >
            {{ tagName }}
          </base-badge>
        </div>
        <div />
      </div>
    </nuxt-link>
  </base-card>
</template>
<script lang="ts" setup>
defineProps({
  blog: {
    type: Object,
    required: true,
  },
  useCategory: {
    type: Boolean,
    default: true,
  },
  path: {
    type: String,
    required: true,
  },
});

function convertTimestampToDate(date) {
  const timestamp = new Date(date);
  const day = timestamp.getDay();
  const month = timestamp.getMonth();
  const year = timestamp.getFullYear();
  return `${day}.${month}.${year}`;
}
</script>

<style lang="scss" scoped>
.card-wrapper {
  height: 100%;
  padding: 2rem;
  border: 2px solid white;
  border-radius: $border-radius-card;
  box-shadow: $shadow-small;
}

.thumbnail {
  margin-bottom: 1rem;
  overflow: hidden;
  border-top-right-radius: $border-radius-card;
  border-top-left-radius: $border-radius-card;
  aspect-ratio: 16 / 9;
}

.blog-heading {
  margin-bottom: 2rem;
  display: -webkit-box;
  font-size: $font-large--1;
  color: var(--color-white);
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  color: var(--color-white-shade);

  .date {
    font-size: 1.6rem;
    color: var(--color-grey-txt);
  }
}

.wrapper-badges {
  display: flex;
  gap: 1rem;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
