import endpoints from '@/endpoints/message';
import type { ContactFields } from '@/types/api/message';
// const config = useRuntimeConfig();
const { publicMessageUrl } = endpoints;

const baseHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'Access-Control-Allow-Origin': 'true',
};

export default {
  async submitFormMessage(fields: ContactFields) {
    return await fetch(publicMessageUrl, {
      method: 'POST',
      headers: baseHeaders,
      body: JSON.stringify(fields),
    });
  },
};
