<template>
  <div class="bubble-container">
    <img
      :src="png"
      :aria-label="ariaLabel"
      :alt="altText"
      class="bubble-container__bubble"
    >
  </div>
</template>

<script setup lang="ts">
import bubblePng from '@/assets/img/bubble.png'
import ringPng from '@/assets/img/donut.png'

const props = defineProps({
  png: {
    type: String,
    default: 'ring',
    validator (value: string): boolean {
      return value === 'ring' || value === 'bubble'
    }
  },
  altText: {
    type: String,
    default: 'Bubble floating around the page'
  },
  ariaLabel: {
    type: String,
    default: 'Bubble floating around the page'
  }
})

const png = computed(() => {
  if (props.png === 'ring') {
    return ringPng
  } else {
    return bubblePng
  }
})
</script>

<style scoped lang="scss">
.bubble-container {
  height: 28rem;

  @include respond(tab-land) {
    height: 20rem;
    width: 20rem;
  }

  &__bubble {
    height: 100%;
  }
}
</style>
