<template>
  <div class="mouse">
    <div class="mouse__dot" />
  </div>
</template>

<script lang="ts" setup></script>

<style scoped lang="scss">
.mouse {
  width: 4.8rem;
  height: 8rem;
  border: 2px solid $color-white;
  border-radius: $border-radius-default;
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  @include respond(phone) {
    transform: translate(-50%, -100%);
  }

  &__dot {
    width: 0.96rem;
    height: 0.96rem;
    background-color: var(--color-white);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 41%;
    animation: scroll 3s 2s infinite normal linear;
  }
}
</style>
