<script setup lang="ts">
import { Bar } from 'vue-chartjs';
import type { ChartComponent } from 'chart.js';
import { onBeforeUnmount, onUnmounted } from 'vue';
import 'chart.js/auto';
// ************* TYPES ************* //
interface Props {
  data: {
    labels: string[];
    datasets: {
      label: string;
      backgroundColor: string[];
      data: number[];
    }[];
  };
  options: {
    responsive: boolean;
    maintainAspectRatio: boolean;
    scales: {
      yAxes: {
        ticks: {
          beginAtZero: boolean;
        };
      }[];
    };
  };
}

// ************* PROPS ************* //
const props = defineProps<Props>();

const barChart = ref<ChartComponent>(null);

onUnmounted(() => {
  barChart.value.chart.clear();
  barChart.value.chart.destroy();
});
</script>

<template>
  <div>
    <Bar ref="barChart" :data="data" :options="options" />
  </div>
</template>

<style scoped lang="scss"></style>
