<template>
  <section
    id="hero-page"
    :ref="(el) => addNode('heroPage', el)"
    class="section-hero u-margin-bottom-page"
    @mouseover="getCoords"
  >
    <HeroIntro />
    <floating-elements :coordinates="coordinates" />
    <scroll-mouse />
  </section>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import ScrollMouse from '@/components/library/icons/ScrollMouse.vue';
import HeroIntro from '@/components/sections/hero/HeroIntro.vue';
import FloatingElements from '@/components/sections/hero/FloatingElements.vue';
import { useAppStore } from '@/stores/appStore';

const { addNode } = useAppStore();

const coordinates = ref({ x: 0, y: 0 });

function getCoords(event: MouseEvent) {
  const x = event.clientX;
  const y = event.clientY;
  coordinates.value = { x, y };
}
</script>

<style scoped lang="scss">
.section-hero {
  padding: 10rem 10rem 4rem 10rem;
  position: relative;
  height: calc(100vh - 14rem);
  max-width: 140rem;
  margin: 0 auto;
  overflow-x: hidden;

  @include respond(tab-land) {
    padding: 4rem 10rem 4rem 10rem;
  }
  @include respond(phone) {
    padding: 4rem 2rem 0rem 2rem;
    height: calc(100vh - 9.9rem);
  }
}
</style>
