<template>
  <ul class="txt-container__list">
    <slot>
      <li
        v-if="listInfos"
        v-for="(info, index) in listInfos"
        :key="'info' + index"
        :ref="
          (el: HTMLElement) => addNode('qualityItem', el, { pushValue: true })
        "
        class="txt-container__qualities"
      >
        {{ info.main }} <span>{{ info.side }}</span>
      </li>
    </slot>
  </ul>
</template>

<script lang="ts" setup>
// ************* TYPES ************* //
import { useAppStore } from '@/stores/appStore';

interface Item {
  main: string;
  side: string;
}
interface Props {
  listInfos?: Item[];
}

// ************* PROPS ************* //
const props = defineProps<Props>();

const { addNode } = useAppStore();
</script>

<style scoped lang="scss">
.txt-container {
  &__list {
    list-style: none;

    & span {
      display: block;
      // margin-left: 2rem;
    }
  }

  &__qualities {
    position: relative;

    &:not(:last-child) {
      margin-bottom: $spacing-small;
    }

    &:last-child {
      margin-bottom: $spacing-small--2;
    }

    &::before {
      content: ' ';
      display: block;
      position: absolute;
      width: 0.8rem;
      height: 0.8rem;
      background: $gradient-primary;
      border-radius: 50%;
      margin-right: 2rem;
      transform: translate(-200%, 130%);
    }
  }
}
</style>
