<script setup lang="ts">
import { PolarArea } from 'vue-chartjs';
import { onBeforeUnmount } from 'vue';
import type { ChartComponent } from 'chart.js';
import 'chart.js/auto';

// ************* TYPES ************* //
interface Props {
  data: {
    labels: string[];
    datasets: {
      label: string;
      backgroundColor: string[];
      data: number[];
    }[];
  };
  options: {
    responsive: boolean;
    maintainAspectRatio: boolean;
    scales: {
      yAxes: {
        ticks: {
          beginAtZero: boolean;
        };
      }[];
    };
  };
}

// ************* PROPS ************* //
const props = defineProps<Props>();

const polarChart = ref<ChartComponent>(null);

onBeforeUnmount(() => {
  polarChart.value.chart.clear();
  polarChart.value.chart.destroy();
});
</script>

<template>
  <div class="wrapper__polar">
    <PolarArea ref="polarChart" :data="data" :options="options" />
  </div>
</template>

<style scoped lang="scss"></style>
