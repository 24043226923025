<template>
  <section id="stacks" class="section-stacks">
    <div class="section-stacks__lang-container">
      <h2 class="heading heading-2">
        {{ $t('stacks.title') }}
      </h2>

      <div class="lang-container__stacks-box">
        <div class="flexColumn">
          <div class="frontend-wrapper">
            <h3>Frontend</h3>
            <base-icon
              class="icon"
              v-for="(icon, index) in frontEndIcons"
              size="large"
              :icon="icon"
              :key="'f' + index"
            />
          </div>
          <div class="backend-wrapper">
            <h3>Backend</h3>
            <base-icon
              class="icon"
              v-for="(icon, index) in backendIcons"
              :icon="icon"
              size="large"
              :key="'b' + index"
            />
          </div>
        </div>
      </div>

      <div class="lang-container__icon-box">
        <img
          class="illustration-icon__languages"
          src="~/assets/svgs/Illustration_Language.svg"
          alt="Illustration of medidating code"
        />
      </div>
    </div>

    <div class="section-stacks__pac-man-box">
      <img
        src="~/assets/svgs/pac-man.svg"
        alt="pac man background"
        class="svg__pac-man"
      />
    </div>

    <div class="section-stacks__tech-container">
      <h2 class="heading heading-2">
        {{ $t('stacks.title-2') }}
      </h2>

      <div class="tech-container__tech-wrapper">
        <div>
          <base-icon
            class="icon"
            v-for="(icon, index) in techIcons"
            :icon="icon"
            size="large"
            :key="'s' + index"
          />
        </div>

        <div class="tech-container__icon-box">
          <img
            class="illustration-icon__technology"
            src="~/assets/svgs/Illustration_Technology.svg"
            alt="Illustrations icon for technology"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
const techIcons = [
  'npm',
  'github',
  'git',
  'figma',
  'blender',
  'ethereum',
  'docker',
];

const frontEndIcons = [
  'html',
  'css',
  'tailwindcss',
  'sass',
  'solidity',
  'javascript',
  'react',
  'redux',
  'vue',
  'nuxt',
];

const backendIcons = ['laravel', 'mysql', 'nodejs', 'mongodb'];
</script>

<style scoped lang="scss">
.section-stacks {
  padding: 0rem 10rem;
  position: relative;
  max-width: 140rem;
  margin: 0 auto $spaceing-page;

  @include respond(phone) {
    padding: 2rem 2rem;
  }

  &__pac-man-box {
    width: 60rem;
    height: 60rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    & .svg__pac-man {
      width: 100%;
      height: 100%;
    }
  }

  //  Stacks container left side
  &__lang-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    // margin-bottom: 8rem;

    @include respond(tab-land) {
      flex-direction: column;
      align-items: center;
      margin-bottom: 0rem;
    }

    //  Stacks container top
    & .heading {
      flex-basis: 100%;
      margin-bottom: 8rem;

      @include respond(tab-land) {
        // margin-bottom: 10rem;
      }
    }

    .lang-container__stacks-box {
      flex-basis: 50%;
      display: flex;
      flex-wrap: wrap;
      gap: 4rem;
      justify-content: flex-start;
      margin-bottom: 8rem;

      // margin-bottom: 35rem;

      @include respond(tab-land) {
        flex-basis: 100%;
        order: 3;
        justify-content: center;
      }

      .backend-wrapper,
      .frontend-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 3rem;
        border: 2px solid $color-white;
        border-radius: 2rem;
        padding: 2rem;

        @include respond(tab-land) {
          justify-content: center;
        }

        :first-child {
          flex-basis: 100%;
        }
      }

      .frontend-wrapper {
        margin-bottom: 4rem;
      }
    }

    //  Stacks container right side
    .lang-container__icon-box {
      flex-basis: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @include respond(tab-land) {
        margin-bottom: 6rem;
      }
    }
  }

  .illustration-icon {
    &__languages {
      max-width: 39rem;
      height: 25rem;

      @include respond(phone) {
        max-width: 100%;
      }
    }

    &__technology {
      max-width: 46rem;
      height: 22rem;
      @include respond(phone-small) {
        //height: auto;
        max-width: 100%;
      }
    }
  }

  //  Stacks page center
  &__center-container {
    position: relative;
    height: 30rem;
    display: flex;
    justify-content: center;

    & :not(:last-child) {
      margin-right: 4rem;
    }

    @include respond(tab-land) {
      height: 50rem;
    }
  }

  .icon {
    fill: var(--color-white);
    color: var(--color-white);
  }

  //  Language container right side
  &__tech-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include respond(tab-land) {
      flex-direction: column;
      align-items: center;
    }

    // Language container top
    & .heading {
      flex-basis: 100%;
      text-align: right;
      order: -1;
      margin-bottom: 8rem;

      @include respond(tab-land) {
        text-align: center;
      }

      @include respond(phone-small) {
        margin-left: -2rem;
      }
    }

    .tech-container__tech-wrapper {
      display: flex;
      align-items: center;
      width: 100%;

      @include respond(tab-land) {
        flex-direction: column;
      }

      > :first-child {
        flex-basis: 50%;
        display: flex;
        flex-wrap: wrap;
        border: 2px solid $color-white;
        border-radius: 2rem;
        padding: 2rem;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 4rem;
        @include respond(tab-land) {
          flex-basis: 100%;
          justify-content: center;
        }
      }
    }

    .heading-challenges {
      @include respond(tab-land) {
        margin-top: 4rem;
      }
    }

    //  Language container left side
    .tech-container__icon-box {
      flex-basis: 50%;
      order: -1;
      @include respond(tab-land) {
        margin-bottom: 8rem;
      }
    }
  }
}
</style>
